export default {
  api: {
    production_link: 'https://pyn5u8h0qe.execute-api.sa-east-1.amazonaws.com/dev/',
    development_link: 'https://pyn5u8h0qe.execute-api.sa-east-1.amazonaws.com/dev/'
  },
  me: {
    cache_name: "user",
    login_field: "Nome de Usuário",
  },
  system: {
    register: true,
    name: "BRPREV",
    copyright: "© 2020 BRPREV",
    logo: "assets/images/logo/logo.png",
    recover_password: "sms",
  },
  developer: {
    name: "",
    link: "",
  },
};
/*
 api: {
    production_link: 'https://crtjiyk1o8.execute-api.sa-east-1.amazonaws.com/dev/',
    development_link: 'https://crtjiyk1o8.execute-api.sa-east-1.amazonaws.com/dev/'
  },

  api: {
    production_link: 'https://pyn5u8h0qe.execute-api.sa-east-1.amazonaws.com/dev/',
    development_link: 'https://pyn5u8h0qe.execute-api.sa-east-1.amazonaws.com/dev/'
  },

 api: {
    production_link: 'http://localhost:15001/',
    development_link: 'http://localhost:15001/'
  },*/
