<ngx-spinner></ngx-spinner>

<!--Login Page Starts-->
<section id="login">
  <div class="row auth-height full-height-vh m-0 black">
    <div class="col-12 p-0 d-flex align-items-center justify-content-center">
      <div
        class="card bg-transparent m-0 overflow-hidden"
        style="max-width: 1300px"
      >
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0 auth-img-bg">
              <!--Isso aqui possívelmente é o elemento da oferta de onboarding-->
              <div
                class="col-lg-6 d-flex flex-column justify-content-start align-items-center px-1 py-4 p-md-3"
                *ngIf="
                  isPromoParc &&
                    activePromo &&
                    (activePromo === 5 || activePromo === 11) &&
                    planoEscolhido &&
                    planoEscolhido.name === 'Anual' &&
                    planoEscolhido.gatewayIndetifer === 'plano_3';
                  else placeholderImage
                "
              >
                <h3 class="text-center black font-medium-3">
                  Garanta Somente <b class="text-warning">HOJE</b>
                </h3>
                <h3 class="text-muted text-center font-small-3 mb-3">
                  Encerra em<br /><span
                    class="black font-medium-3"
                    *ngIf="configTimer.leftTime > 0; else timerDone"
                  >
                    <countdown
                      (event)="doneTimer($event)"
                      [config]="configTimer"
                    ></countdown>
                  </span>
                  <ng-template #timerDone>
                    <span class="black font-medium-3">00:30:00</span>
                  </ng-template>
                </h3>
                <div class="row m-0 justify-content-center align-items-center">
                  <div
                    class="col-6 p-0 d-flex justify-content-center col-md-12 mb-1 mb-md-0"
                    *ngIf="activePromo !== 11"
                  >
                    <img
                      src="../../../../assets/img/annual_plan_months.svg"
                      alt=""
                      class="img-fluid d-none d-md-block"
                      width="513"
                      height="123"
                    />
                    <img
                      src="../../../../assets/img/annual_plan_mobile_months.svg"
                      alt=""
                      class="img-fluid d-md-none"
                      width="185"
                      height="106"
                      [ngStyle]="
                        !!hoveredBonusBloon &&
                        hoveredBonusBloon !== 'months' && { opacity: '0.3' }
                      "
                      (mouseenter)="onMouseEnterBloon('months')"
                      (mouseleave)="onMouseLeaveBloon()"
                      placement="right"
                      [ngbTooltip]="monthsTipContent"
                    />
                    <ng-template #monthsTipContent>
                      <span class="font-small-2 d-block tooltip-content">
                        Você pagará 12 meses, porém terá acesso ao Personal
                        Digital durante <b>14 meses.</b>
                      </span>
                    </ng-template>
                  </div>
                  <div
                    class="col-6 p-0 d-flex justify-content-center col-md-12 mb-1 mb-md-0"
                    *ngIf="activePromo !== 11"
                  >
                    <img
                      src="../../../../assets/img/annual_plan_course.svg"
                      alt=""
                      class="img-fluid d-none d-md-block"
                      width="513"
                      height="123"
                    />
                    <img
                      src="../../../../assets/img/annual_plan_mobile_course.svg"
                      alt=""
                      class="img-fluid d-md-none"
                      width="185"
                      height="106"
                      [ngStyle]="
                        !!hoveredBonusBloon &&
                        hoveredBonusBloon !== 'course' && { opacity: '0.3' }
                      "
                      (mouseenter)="onMouseEnterBloon('course')"
                      (mouseleave)="onMouseLeaveBloon()"
                      placement="left"
                      [ngbTooltip]="courseTipContent"
                    />
                    <ng-template #courseTipContent>
                      <span class="font-small-2 d-block tooltip-content">
                        Treinamento completo, com o passo a passo para
                        <b>montar</b> e <b>vender</b> consultoria online.
                      </span>
                    </ng-template>
                  </div>
                  <div
                    class="col-6 p-0 d-flex justify-content-center col-md-12 mb-1 mb-md-0"
                  >
                    <img
                      src="../../../../assets/img/annual_plan_trainings.svg"
                      alt=""
                      class="img-fluid d-none d-md-block"
                      width="513"
                      height="123"
                    />
                    <img
                      src="../../../../assets/img/annual_plan_mobile_trainings.svg"
                      alt=""
                      class="img-fluid d-md-none"
                      width="185"
                      height="106"
                      [ngStyle]="
                        !!hoveredBonusBloon &&
                        hoveredBonusBloon !== 'trainings' && { opacity: '0.3' }
                      "
                      (mouseenter)="onMouseEnterBloon('trainings')"
                      (mouseleave)="onMouseLeaveBloon()"
                      placement="right"
                      [ngbTooltip]="trainingsTipContent"
                    />
                    <ng-template #trainingsTipContent>
                      <ol class="pl-2 font-small-1 tooltip-content m-0">
                        <li class="pl-1">
                          <span class="w-100 d-block text-left"
                            >Seguidores lucrativos</span
                          >
                        </li>
                        <li class="pl-1">
                          <span class="w-100 d-block text-left"
                            >Imersão A VIrada</span
                          >
                        </li>
                        <li class="pl-1">
                          <span class="w-100 d-block text-left"
                            >Os 4 passos Para o Personal Dobrar o
                            Faturamento</span
                          >
                        </li>
                        <li class="pl-1">
                          <span class="w-100 d-block text-left"
                            >De Professor a Empresário</span
                          >
                        </li>
                        <li class="pl-1">
                          <span class="w-100 d-block text-left"
                            >Como Montar sua Consultoria Online</span
                          >
                        </li>
                      </ol>
                    </ng-template>
                  </div>
                  <div
                    class="col-6 p-0 d-flex justify-content-center col-md-12 mb-1 mb-md-0"
                  >
                    <img
                      src="../../../../assets/img/annual_plan_ment.svg"
                      alt=""
                      class="img-fluid d-none d-md-block"
                      width="513"
                      height="123"
                    />
                    <img
                      src="../../../../assets/img/annual_plan_mobile_ment.svg"
                      alt=""
                      class="img-fluid d-md-none"
                      width="185"
                      height="106"
                      [ngStyle]="
                        !!hoveredBonusBloon &&
                        hoveredBonusBloon !== 'ment' && { opacity: '0.3' }
                      "
                      (mouseenter)="onMouseEnterBloon('ment')"
                      (mouseleave)="onMouseLeaveBloon()"
                      placement="left"
                      [ngbTooltip]="mentTipContent"
                    />
                    <ng-template #mentTipContent>
                      <span class="font-small-2 d-block tooltip-content">
                        Encontros mensais ao vivo, sempre com um conteúdo novo
                        específico e aberto à dúvidas. As aulas ficam gravadas
                        no
                        <b>OPD Academy</b>.
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <ng-template #placeholderImage>
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center align-items-center auth-img-bg p-3"
                >
                  <img
                    src="../../../../assets/img/gallery/vector-web-laranja.png"
                    alt=""
                    class="img-fluid"
                    width="400"
                    height="330"
                  />
                </div>
              </ng-template>
              <div class="col-lg-6 bg-white form-wrapper col-12 px-4 py-3">
                <h3 class="text-warning text-center" style="font-weight: 700;">Oferta Cyber Week</h3>
                <!-- h4 class="text-warning text-center" style="font-weight: 700;">
                  Esta Oferta Encerra em <span
                    *ngIf="configTimer.leftTime > 0"
                    >
                      <countdown
                        (event)="doneTimer($event)"
                        [config]="configTimer"
                      ></countdown></span>
                </h4> -->
                <ul class="fa-ul text-warning" style="font-size: 1em;font-weight: 500;">
                  <li>
                    <i class="fa-li ft-check-circle"></i>2 Meses Grátis
                    (Você pagará 6 e terá acesso durante 8 meses)
                  </li>                 
                  <li>
                    <i class="fa-li ft-check-circle"></i> Comunidade Arena
                  </li>
                  <li>
                    <i class="fa-li ft-check-circle"></i> Loja do Personal
                  </li>                 
                  </ul>                
                <div
                  class="row mb-2"
                  *ngIf="
                    (activePromo && activePromo !== 5) ||
                    (planoEscolhido && planoEscolhido.gatewayIdentifer !== 'plano_3')
                  "
                >
                  <div class="col">
                    <h4
                      class="text-warning"
                      *ngIf="activePromo && activePromo == 1"
                    >
                      Garanta <b>somente HOJE</b> uma promoção exclusiva com
                      <b>50% de desconto</b> na primeira mensalidade!
                    </h4>
                    <h4
                      class="text-warning"
                      *ngIf="activePromo && activePromo == 2"
                    >
                      Garanta <b>somente HOJE</b> uma promoção exclusiva com
                      <b>30% de desconto</b> na primeira mensalidade!
                    </h4>
                    <h4
                      class="text-warning"
                      *ngIf="activePromo && activePromo == 3"
                    >
                      Garanta <b>somente HOJE</b> uma promoção exclusiva com
                      <b>15% de desconto</b> na primeira mensalidade!
                    </h4>
                    <h4
                      class="text-warning"
                      *ngIf="activePromo && activePromo == 4"
                    >
                      Garanta <b>somente HOJE</b> uma promoção exclusiva pagando
                      apenas <b>R$ 1 REAL</b> na primeira mensalidade!
                    </h4>
                    <!--<h4 
                      class="text-warning text-center"
                      *ngIf="
                        (!promo1 &&
                          !promo2 &&
                          !promo3 &&
                          !isPromoParc &&
                          !activePromo) ||
                        (isPromoParc &&
                          planoEscolhido &&
                          planoEscolhido.name != 'Anual' &&
                          planoEscolhido.name != 'Semestral')
                      "
                    >
                      {{
                        venceuDiasGratuitos
                          ? "Seus dias gratuitos acabaram. Garanta agora nossos planos em promoção!"
                          : "Garanta agora nossos planos em promoção!"
                      }}
                    </h4> -->
                    
                    <h3
                      class="text-warning text-center"
                      *ngIf="
                        isPromoParc &&
                        planoEscolhido &&
                        (planoEscolhido.name == 'Anual' ||
                          planoEscolhido.name == 'Semestral') &&
                        activePromo != 9 &&
                        activePromo !== 11
                      "
                    >
                      🎁 Garanta <b>Somente HOJE </b>
                    </h3>
                    <h2
                      class="text-warning text-center"
                      *ngIf="
                        activePromo &&
                        activePromo == 5 &&
                        planoEscolhido &&
                        planoEscolhido.name == 'Anual'
                      "
                    >
                      Esta Oferta Encerra em<br /><span
                        *ngIf="configTimer.leftTime > 0"
                        ><b>
                          <countdown
                            (event)="doneTimer($event)"
                            [config]="configTimer"
                          ></countdown> </b
                      ></span>
                    </h2>
                    <div
                      class="col-12"
                      *ngIf="
                        isPromoParc &&
                        planoEscolhido &&
                        planoEscolhido.name == 'Anual'
                      "
                    >
                      <ul class="fa-ul text-warning" style="font-size: 1.15em">
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 || activePromo == 6) &&
                            planoEscolhido.name == 'Anual'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>2 Meses Grátis
                          (Você pagará 12 e terá acesso durante 14 meses)
                        </li>
                        <li *ngIf="activePromo && activePromo == 9">
                          <i class="fa-li ft-check-circle"></i>4 Meses Grátis
                          (Você pagará 12 e terá acesso durante 16 meses)
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            activePromo == 7 &&
                            planoEscolhido.name == 'Anual'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>1 Mês Grátis
                          (Você pagará 12 e terá acesso durante 13 meses)
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            activePromo == 7 &&
                            planoEscolhido.name == 'Semestral'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>1 Mês Grátis (A
                          parcela sai de <del>R$ 46,55</del> por R$ 39,90)
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 || activePromo == 6) &&
                            planoEscolhido.name == 'Semestral'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>1 Mês Grátis (A
                          parcela sai de <del>R$ 46,55</del> por R$ 39,90)
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 6 ||
                              activePromo == 7 ||
                              activePromo == 8 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b>Seguidores Lucrativos</b>
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 6 ||
                              activePromo == 7 ||
                              activePromo == 8 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b
                            >Os 4 Passos para o Personal Dobrar o faturamento</b
                          >
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 6 ||
                              activePromo == 7 ||
                              activePromo == 8 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b>Imersão A Virada</b>
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b>De Professor a Empresário</b>
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b>Como montar a consultoria online</b>
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 || activePromo == 9)
                          "
                        >
                          <i class="fa-li ft-star-on"></i
                          ><b>Curso O Personal Digital</b>
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-star-on"></i
                          ><b
                            >Mentoria Mensal com Diego Medeiros (durante 1
                            ano)</b
                          >
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            (activePromo == 5 ||
                              activePromo == 9 ||
                              activePromo == 10)
                          "
                        >
                          <i class="fa-li ft-star-on"></i><b>OPD Academy</b>
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            activePromo == 8 &&
                            planoEscolhido.name == 'Anual'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>1 Mês Grátis
                          (Você pagará 11 e terá acesso durante 12 meses)
                        </li>
                        <li
                          *ngIf="
                            activePromo &&
                            activePromo == 8 &&
                            planoEscolhido.name == 'Semestral'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>1 Mês Grátis
                          (Você pagará 5 e terá acesso durante 6 meses)
                        </li>
                      </ul>
                    </div>
                    <div
                      class="col-12"
                      *ngIf="planoEscolhido && planoEscolhido.name == 'Anual'"
                    >
                      <ul class="fa-ul text-warning" style="font-size: 1.15em">
                        <li
                          *ngIf="
                            activePromo &&
                            activePromo == 4 &&
                            planoEscolhido.name == 'Anual'
                          "
                        >
                          <i class="fa-li ft-check-circle"></i>Primeiro Mês a 1
                          REAL (Você pagará 1 real no primeiro mês)
                        </li>
                        <li *ngIf="activePromo && activePromo == 4">
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b>Seguidores Lucrativos</b>
                        </li>
                        <li *ngIf="activePromo && activePromo == 4">
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b
                            >Os 4 Passos para o Personal Dobrar o faturamento</b
                          >
                        </li>
                        <li *ngIf="activePromo && activePromo == 4">
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b
                            >De Professor a Empresário</b
                          >
                        </li>
                        <li *ngIf="activePromo && activePromo == 4">
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b>Imersão A Virada</b>
                        </li>
                        <li *ngIf="activePromo && activePromo == 4">
                          <i class="fa-li ft-check-circle"></i>Treinamento
                          <b
                            > Como Montar a Sua Consultoria Online</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h5
                  class="text-center font-medium-1 grey d-flex flex-column align-items-center"
                  [ngClass]="
                    !!priceToShow() &&
                    planoEscolhido &&
                    (activePromo || cupomRetornado)
                      ? 'mb-2'
                      : 'mb-4'
                  "
                  *ngIf="planoEscolhido"
                >
                  <span class="text-warning font-medium-2 text-bold-700">
                    {{ planoEscolhido.name }}
                  </span>
                  <s
                    class="mt-3"
                    *ngIf="
                      planoEscolhido && planoEscolhido.gatewayIdentifer == 'plano_4'
                    "
                    ><b>De:</b> R$ 46,55/mês</s
                  >
                  <s
                    class="mt-2"
                    *ngIf="promo1 || promo2 || promo3 || cupomRetornado"
                    >{{ planoEscolhido.amountLabel }}</s
                  >
                  <span
                    class="badge badge-pill px-2 mt-2"
                    *ngIf="!promo1 && !promo2 && !promo3 && !cupomRetornado"
                  >
                    <i class="fa fa-tags"></i>
                    <span class="ml-1">
                      <span
                        *ngIf="
                          planoEscolhido.identifier == 'plano_4';
                          else notPlan4
                        "
                      >
                        {{ "Por: " + planoEscolhido.amount }}
                      </span>
                      <ng-template #notPlan4>
                        <b>{{
                          planoEscolhido.amountLabel }}</b>                      
                      </ng-template>
                    </span>
                  </span>
                </h5>
                <h4
                  class="mb-4 d-flex justify-content-center"
                  *ngIf="
                    !!priceToShow() &&
                    planoEscolhido &&
                    (activePromo || cupomRetornado)
                  "
                >
                  <span class="badge badge-pill px-2 font-medium-1">
                    <i class="fa fa-tags"></i>
                    {{ priceToShow() }}
                  </span>
                </h4>
                <form #exForm="ngForm" class="editForm" novalidate>
                  <div class="form-row" *ngIf="!user">
                    <div class="col">
                      <div class="form-group">
                        <div class="controls">
                          <label for="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            class="form-control"
                            autocomplete="off"
                            [(ngModel)]="usuario_email"
                            (focus)="perdeuFocoEmail = false"
                            (blur)="perdeuFocoEmail = true"
                            (change)="verificaEmail()"
                            name="email"
                            placeholder="Email"
                          />
                          <p
                            *ngIf="isEmailInvalido"
                            class="text-muted text-danger mt-1"
                          >
                            Digite um email válido.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="form-row"
                      *ngIf="
                        isEmailCadastrado &&
                        validateEmail(usuario_email) &&
                        perdeuFocoEmail &&
                        !user
                      "
                    >
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="senha">Senha</label>
                            <input
                              type="password"
                              id="senha-cadastro"
                              class="form-control"
                              autocomplete="off"
                              [(ngModel)]="userToLogin.senha"
                              (change)="login()"
                              name="senha"
                              placeholder="Senha"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      *ngIf="loginInvalido"
                      class="text-muted text-danger mt-1"
                    >
                      Erro ao fazer Login. Email ou senha inválido. Tente
                      Novamente.
                    </p>
                  </div>
                  <div
                    *ngIf="
                      !isEmailCadastrado &&
                      validateEmail(usuario_email) &&
                      perdeuFocoEmail
                    "
                  >
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="senha">Senha</label>
                            <input
                              type="text"
                              id="senha"
                              class="form-control"
                              [(ngModel)]="usuario.senha"
                              name="senha"
                              placeholder="Senha"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome">Nome</label>
                            <input
                              type="text"
                              id="nome"
                              class="form-control"
                              [(ngModel)]="usuario.nome"
                              name="nome"
                              placeholder="Nome"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="phone">Telefone</label>
                            <ngx-intl-tel-input
                              [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="true"
                              [enablePlaceholder]="true"
                              [searchCountryFlag]="true"
                              [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                              ]"
                              [selectFirstCountry]="false"
                              [selectedCountryISO]="CountryISO.Brazil"
                              [maxLength]="15"
                              [phoneValidation]="true"
                              [separateDialCode]="separateDialCode"
                              [numberFormat]="PhoneNumberFormat.National"
                              name="phone"
                              searchCountryPlaceholder="Selecione o País"
                              [(ngModel)]="usuario.phone"
                            >
                            </ngx-intl-tel-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="tipopagamento">Forma de pagamento</label>
                        <ng-select
                          [items]="formasFiltered"
                          bindLabel="name"
                          name="tipopagamento"
                          (change)="changeForma()"
                          placeholder="Selecionar Forma"
                          id="tipopagamento"
                          [(ngModel)]="formaEscolhida"
                          [clearable]="false"
                        ></ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="planoEscolhido">Planos</label>
                        <ng-select
                          [items]="plansFiltered"
                          bindLabel="name"
                          name="planoEscolhido"
                          (change)="changePlano()"
                          placeholder="Selecionar Plano"
                          id="planoEscolhido"
                          [(ngModel)]="planoEscolhido"
                          [clearable]="false"
                        ></ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row" *ngIf="!hidecupom">
                    <div class="col">
                      <div class="form-group">
                        <div class="controls">
                          <label for="cupom">Cupom</label>
                          <input
                            type="text"
                            id="cupom"
                            class="form-control"
                            [(ngModel)]="cupomDigitado"
                            (change)="verificaCupom()"
                            (keydown.space)="$event.preventDefault()"
                            name="cupom"
                            placeholder="Cupom"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    *ngIf="isCupomInvalido"
                    class="text-muted text-danger mt-1 text-center"
                  >
                    Cupom inválido ou inativo.
                  </p>

  <app-order-bump *ngIf="activeOrderBump"
  [title]="'Imersão Consultoria On-line Express - 08/12 - 15h às 17h'"
  [description]="'Uma imersão prática, ao vivo e online, onde você vai descobrir  o passo a passo para criar e entregar sua consultoria no app Personal Digital, definir preços, fidelizar clientes e conquistar suas primeiras 10 a 20 vendas.'"
  [price]="'R$ 49,90'"
  [imageUrl]="'assets/produto-extra.jpg'"
  (selectionChange)="onOrderBumpSelectionChange($event)"
></app-order-bump>
                  <div
                    class="row mb-4"
                    *ngIf="
                      formaEscolhida &&
                      formaEscolhida.id == 'credit_card' &&
                      planoEscolhido &&
                      planoEscolhido.name != 'Mensal' &&
                      planoEscolhido.name != 'Semestral'
                    ">



                    <div class="col">
                      <p class="text-center text-muted m-0">
                        Assim que você confirmar esta etapa, sua assinatura
                        {{ planoEscolhido.name }} será ativada com a cobrança de {{ planoEscolhido.amountLabel }}.
                      </p>
                    </div>
                  </div>
                  <form
                    id="boleto-form"
                    style="margin-left: auto; margin-right: auto"
                    *ngIf="formaEscolhida && formaEscolhida.id != 'credit_card'"
                  >
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cpf_cnpj">CPF/CNPJ</label>
                            <input
                              type="text"
                              id="cpf_cnpj"
                              class="form-control"
                              [(ngModel)]="endereco.cpf_cnpj"
                              name="cpf_cnpj"
                              mask="000.000.000-00||00.000.000/0000-00"
                              placeholder="CPF/CNPJ"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cep">CEP</label>
                            <input
                              type="text"
                              id="cep"
                              class="form-control"
                              [(ngModel)]="endereco.zip_code"
                              name="nome"
                              mask="00000-000"
                              placeholder="Cep"
                              (change)="consultaCep()"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-8 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="street">Rua/Avenida</label>
                            <input
                              type="text"
                              id="street"
                              class="form-control"
                              [(ngModel)]="endereco.street"
                              name="street"
                              placeholder="Rua/Avenida"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="number">Número</label>
                            <input
                              type="text"
                              id="number"
                              class="form-control"
                              [(ngModel)]="endereco.number"
                              name="number"
                              placeholder="Número"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="district">Bairro</label>
                            <input
                              type="text"
                              id="district"
                              class="form-control"
                              [(ngModel)]="endereco.district"
                              name="district"
                              placeholder="Bairro"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-8 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="city">Cidade</label>
                            <input
                              type="text"
                              id="city"
                              class="form-control"
                              [(ngModel)]="endereco.city"
                              name="city"
                              placeholder="Cidade"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="state">Estado</label>
                            <ng-select
                              [items]="estados"
                              name="state"
                              placeholder="Estado"
                              id="state"
                              [(ngModel)]="endereco.state"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    class="form-row mt-4"
                    *ngIf="formaEscolhida && formaEscolhida.id == 'credit_card'"
                  >
                    <form
                      id="payment-form"
                      style="
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                      "
                      target="_blank"
                      [ngClass]="getCreditCard()"
                      method="POST"
                    >
                      <div class="usable-creditcard-form">
                        <div class="specifcwrapper">
                          <div class="input-group nmb_a">
                            <div class="icon ccic-brand"></div>
                            <input
                              autocomplete="off"
                              class="credit_card_number"
                              data-iugu="number"
                              placeholder="Número do Cartão"
                              type="text"
                              [(ngModel)]="novoCartao.number"
                              name="ccNumber"
                              ccNumber
                            />
                          </div>
                          <div class="input-group nmb_b">
                            <div class="icon ccic-cvv"></div>
                            <input
                              autocomplete="off"
                              class="credit_card_cvv"
                              data-iugu="verification_value"
                              placeholder="CVV"
                              maxlength="4"
                              type="text"
                              [(ngModel)]="novoCartao.cvv"
                              name="cvv"
                              ccCVC
                            />
                          </div>
                          <div class="input-group nmb_c">
                            <div class="icon ccic-name"></div>
                            <input
                              class="credit_card_name"
                              data-iugu="full_name"
                              placeholder="Titular do Cartão"
                              type="text"
                              [(ngModel)]="novoCartao.name"
                              name="name"
                            />
                          </div>
                          <div class="input-group nmb_d">
                            <div class="icon ccic-exp"></div>
                            <input
                              autocomplete="off"
                              class="credit_card_expiration"
                              data-iugu="expiration"
                              placeholder="MM/AA"
                              mask="00/00"
                              type="text"
                              [(ngModel)]="novoCartao.date"
                              name="date"
                              ccExp
                            />
                          </div>
                        </div>
                        <p *ngIf="erroCartao" class="text-muted text-danger">
                          {{ erroCartao }}
                        </p>
                      </div>
                    </form>
                  </div>
                  <div class="form-row mt-4">
                    <div class="col text-center">
                      <p class="m-0">
                        Ao clicar no botão Assinar você aceita os
                        <a
                          href="https://ajuda.opersonaldigital.com.br/links-uteis/termos-de-servico"
                          src="www.google.com"
                          target="_blank"
                          >Termos de serviço</a
                        >
                        e
                        <a
                          href="https://ajuda.opersonaldigital.com.br/links-uteis/termos-de-uso"
                          src="www.google.com"
                          target="_blank"
                          >Termos de uso</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="form-row mt-2">
                    <div class="col text-center">
                      <button
                        type="button"
                        class="btn btn-lg bg-success text-white btn-block"
                        (click)="
                          formaEscolhida && formaEscolhida.id == 'credit_card'
                            ? cadastrarCartaoEAssinar()
                            : assinar()
                        "
                        [disabled]="disableAssinar()"
                      >
                        Assinar
                      </button>
                    </div>
                  </div>
                  <!-- Disclaimer for specific plans -->
                <div class="form-row mt-1">
                  <p class="text-center font-small-3 text-danger" *ngIf="!parcelado && (planoEscolhido && ['plano_1', 'plano_2', 'plano_3', 'plano_4', 'plano_2_boleto', 'plano_1'].includes(planoEscolhido.identifier))">
                    Atenção: O I.A.GO não está disponível neste plano, mas não se preocupe, <a href="https://app.opersonaldigital.com.br/pages/checkout-iago?checkoutmode=4&plano=plano_3_iago" class="text-primary">clique aqui</a> e garanta um plano com acesso ao I.A.GO agora mesmo.
                  </p>
                </div>
                  <div class="form-row mt-1">
                    <p
                      class="text-center font-small-3 m-0"
                      *ngIf="
                        planoEscolhido &&
                        (promo1 || promo2 || promo3 || cupomRetornado)
                      "
                    >
                      {{
                        "*Renovação automática ativada. Preço a partir da renovação: " +
                          planoEscolhido.amountLabel
                      }}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<jjva-ngx-whatsapp-button
  [ngx_whatsapp_phone]="'+558398576703'"
  [ngx_whatsapp_text]="'Olá, estou no checkout e preciso de ajuda!'"
></jjva-ngx-whatsapp-button>