<div class="modal-header">
  <h5 class="modal-title">
    {{ canEdit ? "Editar Avaliação" : "Visualizar Avaliação" }}
  </h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>

<div class="modal-body">
  <div *ngIf="!canEdit">
    <div class="form-row">
      <ul class="list-group mb-2" style="width: 100%">
        <li *ngFor="let req of listaRequisicoes" class="list-group-item">
          <div class="media">
            <span class="dot align-middle mr-2">{{
              listaRequisicoes.indexOf(req) + 1
            }}</span>
            <div class="media-body">
              <h5 class="mt-0">{{ req.observationFile }}</h5>
              <button
                *ngIf="req.urlFile"
                class="btn btn-warning btn-sm"
                (click)="downloadImage(req.urlFile)"
              >
                <i class="ft ft-download"></i>
              </button>
            </div>
            <img
              *ngIf="isItImage(req.urlFile)"
              [src]="
                req.urlFile || '../../assets/img/portrait/small/noimage.png'
              "
              class="rounded-circle"
              height="100"
              width="100"
              (click)="req.urlFile ? viewMidia(req.urlFile, contentImg) : null"
              style="cursor: pointer"
            />
            <img
              *ngIf="!isItImage(req.urlFile)"
              src="../../assets/img/portrait/small/download-circular-button.png"
              class="rounded-circle"
              height="100"
              width="100"
              (click)="downloadFile(req.urlFile)"
              style="cursor: pointer"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="canEdit">
    <div class="form-row mb-2">
      <div class="col-md-4 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-9">Requisição</label>
          <input
            type="text"
            id="basic-form-9"
            class="form-control"
            [(ngModel)]="requisicaoParaAdicionar"
            placeholder="Ex.: Por favor tire uma foto de seu corpo de frente"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <label for="basic-form-2">Tipo de Resposta</label>
        <ng-select
          [items]="tiposFiles"
          bindLabel="name"
          placeholder="Selecionar tipo de resposta"
          [(ngModel)]="tipoFileSelecionado"
        ></ng-select>
      </div>
      <div class="col-md-3 col-12">
        <label for="t2">Obrigatoriedade</label>
        <ui-switch
          id="t2"
          [checked]="isRequisicaoObrigatoria"
          (change)="changeObrigatoriedadeRequisicao($event)"
        ></ui-switch>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="col-md-6 col-6" *ngIf="idRequisicaoEditada">
        <button
          class="btn btn-danger float-left"
          (click)="cancelarEdicaoRequisicao()"
        >
          Cancelar
        </button>
      </div>
      <div
        [ngClass]="idRequisicaoEditada ? 'col-md-6 col-6' : 'col-md-12 col-12'"
      >
        <button
          class="btn gradient-mint float-right"
          (click)="
            idRequisicaoEditada ? editarRequsicao() : adicionarRequisicao()
          "
        >
          {{ idRequisicaoEditada ? "Atualizar" : "Adicionar" }}
        </button>
      </div>
    </div>

    <div class="form-row">
      <ul class="list-group mb-2" style="width: 100%">
        <li *ngFor="let req of listaRequisicoes" class="list-group-item">
          <div class="media">
            <span class="dot align-middle mr-2">{{
              listaRequisicoes.indexOf(req) + 1
            }}</span>
            <div class="media-body">
              <h5 class="mt-0">{{ req.observationFile }}</h5>
              <span>{{ req.labelType }}</span>
            </div>
            <button
              class="btn btn-outline-primary"
              (click)="liberarEdicaoRequisicao(req)"
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              *ngIf="canEdit"
              class="btn btn-outline-danger"
              (click)="removerRequisicao(req)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
