<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      {{
        !canEdit && avaliation
          ? nomeAvaliacao
          : canEdit && avaliation
          ? "Edição Avaliação"
          : "Nova Avaliação"
      }}
    </h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step
          *ngIf="!(!canEdit && avaliation)"
          [stepTitle]="'Avaliação'"
          [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }"
        >
          <form #firstForm="ngForm" class="editForm" novalidate>
            <br />
            <div
              class="form-row"
              *ngIf="!avaliation && avaliationsModels.length > 0"
            >
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="nome"
                    >selecione uma avaliação de modelo (opcional)</label
                  >
                  <ng-select
                    [items]="avaliationsModels"
                    bindLabel="name"
                    name="model"
                    placeholder="Selecionar Modelo"
                    id="basic-form-model"
                    [(ngModel)]="avaliationsModelSelecionada"
                    (ngModelChange)="onselectModel()"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div
              class="form-row mb-2 scrollable-box-1"
              *ngIf="!(!canEdit && avaliation)"
            >
              <div class="col-6">
                <label for="basic-form-1">Tipo de Avaliação</label>
                <ng-select
                  [disabled]="
                    (avaliation && avaliation.id) || avaliationsModelSelecionada
                  "
                  [appendTo]="'.scrollable-box-1'"
                  [items]="tipoAvaliacao"
                  bindLabel="name"
                  name="fname2"
                  placeholder="Selecionar tipo"
                  id="basic-form-1"
                  [(ngModel)]="tipoAvaliacaoSelecionada"
                  (change)="onChangeAvaliacao()"
                ></ng-select>
              </div>
              <div
                class="col-6"
                *ngIf="
                  tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id == 1
                "
              >
                <div class="form-group">
                  <label for="nome">Nome</label>
                  <input
                    type="text"
                    id="nome"
                    class="form-control"
                    name="company"
                    [(ngModel)]="nomeAvaliacao"
                    #inputAvaliacao="ngModel"
                    required
                  />
                  <small
                    class="form-text text-muted danger"
                    *ngIf="inputAvaliacao.errors?.required"
                    >Informe um nome para a avaliação</small
                  >
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg mr-2"
                  [ngClass]="canEdit ? 'btn-danger' : 'btn-secondary'"
                  (click)="activeModal.close('Close click')"
                >
                  {{ canEdit ? "Cancelar" : "Voltar" }}
                </button>
                <button
                  type="button"
                  class="btn btn-lg btn-warning"
                  [disabled]="
                    tipoAvaliacaoSelecionada == null ||
                    (tipoAvaliacaoSelecionada.id == 1 &&
                      nomeAvaliacao == null) ||
                    (tipoAvaliacaoSelecionada.id == 1 &&
                      nomeAvaliacao != null &&
                      nomeAvaliacao.length == 0)
                  "
                  awNextStep
                >
                  {{ canEdit ? "Próximo Passo" : "Avançar" }}
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>

        <aw-wizard-step
          [stepTitle]="'Perguntas'"
          [navigationSymbol]="{ symbol: '&#xf1c6;', fontFamily: 'FontAwesome' }"
        >
          <form #workForm="ngForm" class="editForm" novalidate>
            <div class="form-row">
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-outline-info float-right"
                  placement="bottom"
                  ngbTooltip="Clique para copiar a avaliação padrão da plataforma."
                  *ngIf="
                    canEdit &&
                    tipoAvaliacaoSelecionada &&
                    tipoAvaliacaoSelecionada.id == 1
                  "
                  (click)="copyFromPlataforma()"
                >
                  Copiar da Plataforma
                </button>
              </div>

              <div class="col-12">
                <h4
                  *ngIf="
                    tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id == 1
                  "
                  class="head text-center mt-3"
                >
                  {{
                    !(!canEdit && avaliation)
                      ? "Informe as perguntas para o aluno"
                      : "Perguntas da Avaliação"
                  }}
                </h4>
                <h4
                  *ngIf="
                    tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id != 1
                  "
                  class="head text-center mt-3"
                >
                  {{ tipoAvaliacaoSelecionada.name }}
                </h4>
                <p
                  *ngIf="
                    tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id != 1
                  "
                  class="text-center mt-3"
                >
                  {{ mensagemAvaliacao }}
                </p>
              </div>
            </div>
            <div class="form-group mb-2" *ngIf="!(!canEdit && avaliation)">
              <label for="nome">Quem irá responder?</label>
              <div class="col-12">
                <label>
                  <input
                    type="radio"
                    [(ngModel)]="choose"
                    (change)="onOptionChange('personal')"
                    name="choose"
                    value="personal"
                  />
                  Personal
                </label>

                <label class="ml-4">
                  <input
                    type="radio"
                    [(ngModel)]="choose"
                    (change)="onOptionChange('aluno')"
                    name="choose"
                    value="aluno"
                  />
                  Aluno
                </label>
              </div>
            </div>

            <br />

            <ng-template
              [ngIf]="
                !canEdit &&
                (avaliation.category == 1 || avaliation.category == null)
              "
            >
              <div class="form-row">
                <ul class="list-group mb-2" style="width: 100%">
                  <li
                    *ngFor="let pergunta of listaPerguntas"
                    class="list-group-item"
                  >
                    <div class="media">
                      <span class="dot align-middle mr-2">{{
                        listaPerguntas.indexOf(pergunta) + 1
                      }}</span>
                      <div class="media-body">
                        <h5 class="mt-0">{{ pergunta.question }}</h5>
                        <span>{{ pergunta.response }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-template>

            <ng-template
              [ngIf]="
                !canEdit &&
                avaliation.category != 1 &&
                avaliation.category != null
              "
            >
              <div class="form-row mb-2">
                <div
                  class="form-row"
                  *ngIf="avaliation && avaliation.category != 9"
                >
                  <div
                    *ngFor="let pergunta of listaPerguntas"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ pergunta.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ pergunta.question }}"
                          class="form-control"
                          name="{{ pergunta.question }}"
                          [(ngModel)]="pergunta.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval">{{
                            pergunta.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-row"
                  *ngIf="avaliation && avaliation.category == 9"
                >
                  <small class="text-center mt-3"
                    >E = Esquerda / D = Direita</small
                  >
                  <div class="col-12">
                    <h4 class="head text-center mt-3">
                      Membros Superiores(em cm)
                    </h4>
                  </div>
                  <div
                    *ngFor="let pergunta of listaPerguntas.slice(0, 6)"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ pergunta.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ pergunta.question + pergunta.measure }}"
                          class="form-control"
                          placeholder="{{
                            pergunta.order % 2 === 0 ? 'Esquerda' : 'Direita'
                          }}"
                          name="{{ pergunta.question + pergunta.measure }}"
                          [(ngModel)]="pergunta.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval">{{
                            pergunta.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <h4 class="head text-center mt-3">
                      Membros Inferiores(em cm)
                    </h4>
                  </div>
                  <div
                    *ngFor="let pergunta of listaPerguntas.slice(6, 14)"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ pergunta.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ pergunta.question + pergunta.measure }}"
                          class="form-control"
                          placeholder="{{
                            pergunta.order % 2 === 0 ? 'Esquerda' : 'Direita'
                          }}"
                          name="{{ pergunta.question + pergunta.measure }}"
                          [(ngModel)]="pergunta.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval">{{
                            pergunta.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h4 class="head text-center mt-3">Outros Membros(em cm)</h4>
                  </div>
                  <div
                    *ngFor="let pergunta of listaPerguntas.slice(14, 20)"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ pergunta.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ pergunta.question }}"
                          class="form-control"
                          name="{{ pergunta.question }}"
                          [(ngModel)]="pergunta.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval">{{
                            pergunta.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h4 class="head text-center mt-3">Diâmetro Ósseo(em cm)</h4>
                  </div>
                  <div
                    *ngFor="let pergunta of listaPerguntas.slice(20, 23)"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ pergunta.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ pergunta.question }}"
                          class="form-control"
                          name="{{ pergunta.question }}"
                          [(ngModel)]="pergunta.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval">{{
                            pergunta.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-row"
                  *ngIf="
                    avaliation &&
                    avaliation.category != 1 &&
                    avaliation.category != 8 &&
                    avaliation.category != 9
                  "
                >
                  <div class="col-12">
                    <h4 class="head text-center mt-3">Resultado</h4>
                  </div>
                  <div
                    *ngFor="let resultado of listaResultados"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ resultado.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          *ngIf="
                            resultado.question == 'Gordura Ideal' ||
                            resultado.question == 'IMC' ||
                            resultado.question == 'Gordura Atual'
                          "
                          id="{{ resultado.question }}"
                          class="form-control"
                          name="{{ resultado.question }}"
                          [(ngModel)]="resultado.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <input
                          type="text"
                          *ngIf="
                            resultado.question != 'Gordura Ideal' &&
                            resultado.question != 'IMC' &&
                            resultado.question != 'Gordura Atual'
                          "
                          mask="separator.2"
                          id="{{ resultado.question }}"
                          class="form-control"
                          name="{{ resultado.question }}"
                          [(ngModel)]="resultado.response"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval">{{
                            resultado.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="canEdit">
              <div
                class="form-row mb-2"
                *ngIf="
                  tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id == 1
                "
              >
                <div class="col-md-5 col-12">
                  <div class="form-group mb-2">
                    <label for="basic-form-2">Pergunta</label>
                    <input
                      type="text"
                      id="basic-form-2"
                      class="form-control"
                      name="fname"
                      [(ngModel)]="perguntaParaAdicionar"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <label for="basic-form-1">Tipo de Resposta</label>
                  <ng-select
                    [items]="tipos"
                    bindLabel="name"
                    name="fname2"
                    placeholder="Selecionar tipo"
                    id="basic-form-1"
                    [(ngModel)]="tipoSelecionado"
                  ></ng-select>
                </div>
                <div class="col-md-3 col-12">
                  <div class="form-group">
                    <label for="ttt">Obrigatoriedade</label>
                    <ui-switch
                      class="switchery switchery-warning"
                      id="ttt"
                      [checked]="isPerguntaObrigatoria"
                      (change)="changeObrigatoriedadePergunta($event)"
                    ></ui-switch>
                  </div>
                </div>
              </div>
              <div
                class="form-row mb-2"
                *ngIf="
                  tipoAvaliacaoSelecionada &&
                  tipoAvaliacaoSelecionada.id != 1 &&
                  tipoAvaliacaoSelecionada.id != 9
                "
              >
                <div
                  class="form-row"
                  style="display: flex; align-items: baseline"
                >
                  <div
                    *ngFor="let avaliacao of avaliacaoSelecionada.questions"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ avaliacao.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          *ngIf="avaliacao.question == 'Altura'"
                          [mask]="'0.00'"
                          id="{{ avaliacao.question }}"
                          class="form-control"
                          name="{{ avaliacao.question }}"
                          [(ngModel)]="avaliacao.response"
                          #inputNomeCompleto="ngModel"
                          required
                          (change)="getResult()"
                          [ngStyle]="{
                            border:
                              avaliacao.question == 'Meta de Gordura'
                                ? '1px solid black'
                                : 'default'
                          }"
                        />
                        <input
                          type="text"
                          *ngIf="avaliacao.question != 'Altura'"
                          mask="separator.2"
                          id="{{ avaliacao.question }}"
                          class="form-control"
                          name="{{ avaliacao.question }}"
                          [(ngModel)]="avaliacao.response"
                          #inputNomeCompleto="ngModel"
                          required
                          (change)="getResult()"
                          [ngStyle]="{
                            border:
                              avaliacao.question == 'Meta de Gordura'
                                ? '1px solid black'
                                : 'default'
                          }"
                        />
                        <div class="input-group-append" *ngIf="1 == 1">
                          <span class="input-group-text" id="group-interval">{{
                            avaliacao.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-row"
                  *ngIf="
                    tipoAvaliacaoSelecionada &&
                    tipoAvaliacaoSelecionada.id != 1 &&
                    tipoAvaliacaoSelecionada.id != 8 &&
                    tipoAvaliacaoSelecionada.id != 9
                  "
                >
                  <div class="col-12">
                    <h4 class="head text-center mt-3">Resultado</h4>
                  </div>
                  <div
                    *ngFor="let result of avaliacaoSelecionada.results"
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ result.name }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          *ngIf="
                            result.name == 'Gordura Ideal' ||
                            result.name == 'IMC' ||
                            result.name == 'Gordura Atual'
                          "
                          id="{{ result.name }}"
                          class="form-control"
                          name="{{ result.name }}"
                          [(ngModel)]="result.value"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                          required
                        />
                        <input
                          type="text"
                          *ngIf="
                            result.name != 'Gordura Ideal' &&
                            result.name != 'IMC' &&
                            result.name != 'Gordura Atual'
                          "
                          mask="separator.2"
                          id="{{ result.name }}"
                          class="form-control"
                          name="{{ result.name }}"
                          [(ngModel)]="result.value"
                          #inputNomeCompleto="ngModel"
                          [disabled]="true"
                          required
                        />
                        <div class="input-group-append" *ngIf="1 == 1">
                          <span class="input-group-text" id="group-interval">{{
                            result.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row mb-2"
                *ngIf="
                  tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id == 9
                "
              >
                <div
                  class="form-row"
                  style="display: flex; align-items: baseline"
                >
                  <small
                    *ngIf="
                      tipoAvaliacaoSelecionada &&
                      tipoAvaliacaoSelecionada.id != 1
                    "
                    class="text-center mt-3"
                    >E = Esquerda / D = Direita</small
                  >
                  <div class="col-12">
                    <h4 class="head text-center mt-3">
                      Membros Superiores(em cm)
                    </h4>
                  </div>
                  <div
                    *ngFor="
                      let avaliacao of avaliacaoSelecionada.questions.slice(
                        0,
                        6
                      )
                    "
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ avaliacao.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ avaliacao.question }}"
                          class="form-control"
                          placeholder="{{
                            avaliacao.order % 2 === 0 ? 'Esquerda' : 'Direita'
                          }}"
                          name="{{ avaliacao.question }}"
                          [(ngModel)]="avaliacao.response"
                          #inputNomeCompleto="ngModel"
                          required
                          (change)="getResult()"
                        />
                        <div class="input-group-append" *ngIf="1 == 1">
                          <span class="input-group-text" id="group-interval">{{
                            avaliacao.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h4 class="head text-center mt-3">
                      Membros Inferiores(em cm)
                    </h4>
                  </div>
                  <div
                    *ngFor="
                      let avaliacao of avaliacaoSelecionada.questions.slice(
                        6,
                        14
                      )
                    "
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ avaliacao.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ avaliacao.question }}"
                          class="form-control"
                          placeholder="{{
                            avaliacao.order % 2 === 0 ? 'Esquerda' : 'Direita'
                          }}"
                          name="{{ avaliacao.question }}"
                          [(ngModel)]="avaliacao.response"
                          #inputNomeCompleto="ngModel"
                          required
                          (change)="getResult()"
                        />
                        <div class="input-group-append" *ngIf="1 == 1">
                          <span class="input-group-text" id="group-interval">{{
                            avaliacao.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h4 class="head text-center mt-3">Outros Membros(em cm)</h4>
                  </div>
                  <div
                    *ngFor="
                      let avaliacao of avaliacaoSelecionada.questions.slice(
                        14,
                        20
                      )
                    "
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ avaliacao.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ avaliacao.question }}"
                          class="form-control"
                          name="{{ avaliacao.question }}"
                          [(ngModel)]="avaliacao.response"
                          #inputNomeCompleto="ngModel"
                          required
                          (change)="getResult()"
                        />
                        <div class="input-group-append" *ngIf="1 == 1">
                          <span class="input-group-text" id="group-interval">{{
                            avaliacao.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h4 class="head text-center mt-3">Diâmetro Ósseo(em cm)</h4>
                  </div>
                  <div
                    *ngFor="
                      let avaliacao of avaliacaoSelecionada.questions.slice(
                        20,
                        23
                      )
                    "
                    class="col-6 col-lg-3"
                  >
                    <div class="form-group mb-2">
                      <label for="pf_nome">{{ avaliacao.question }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          mask="separator.2"
                          id="{{ avaliacao.question }}"
                          class="form-control"
                          name="{{ avaliacao.question }}"
                          [(ngModel)]="avaliacao.response"
                          #inputNomeCompleto="ngModel"
                          required
                          (change)="getResult()"
                        />
                        <div class="input-group-append" *ngIf="1 == 1">
                          <span class="input-group-text" id="group-interval">{{
                            avaliacao.measure
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row mb-2"
                *ngIf="
                  tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id == 1
                "
              >
                <div class="col-md-6 col-6" *ngIf="idPerguntaEditada">
                  <button
                    class="btn btn-danger float-left"
                    type="button"
                    [disabled]="!perguntaParaAdicionar || !tipoSelecionado"
                    (click)="cancelarEdicaoPergunta()"
                  >
                    Cancelar
                  </button>
                </div>
                <div
                  [ngClass]="
                    idPerguntaEditada ? 'col-md-6 col-6' : 'col-md-12 col-12'
                  "
                >
                  <button
                    class="btn gradient-mint float-right"
                    type="button"
                    [disabled]="!perguntaParaAdicionar || !tipoSelecionado"
                    (click)="
                      idPerguntaEditada ? editarPergunta() : adicionarPergunta()
                    "
                  >
                    {{ idPerguntaEditada ? "Atualizar" : "Adicionar" }}
                  </button>
                </div>
              </div>

              <!-- PARTE CERTA -->
              <div class="form-row">
                <ul
                  class="list-group mb-2"
                  [dragula]="'bag-one'"
                  [(dragulaModel)]="listaPerguntas"
                  style="width: 100%"
                >
                  <li
                    *ngFor="let pergunta of listaPerguntas; let i = index"
                    class="list-group-item draggable"
                  >
                    <div class="media">
                      <span class="dot align-middle mr-2">{{ i + 1 }}</span>
                      <div class="media-body">
                        <h5 class="mt-0">{{ pergunta.question }}</h5>
                        <span *ngIf="!personal">{{ pergunta.labelType }}</span>
                      </div>
                      <div class="d-none d-md-block">
                        <i
                          class="fa fa-reorder mr-2 text-muted"
                          placement="top"
                          ngbTooltip="Arraste para reordenar esta pergunta."
                        ></i>
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-icon round mr-1 mb-1"
                          placement="top"
                          ngbTooltip="Duplicar pergunta."
                          (click)="duplicarPergunta(pergunta)"
                        >
                          <i class="fa fa-copy"></i>
                        </button>
                      </div>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-icon round mr-1 mb-1"
                        placement="top"
                        [ngbTooltip]="'Editar pergunta.'"
                        (click)="liberarEdicaoPergunta(pergunta)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-icon round mr-1 mb-1"
                        (click)="removerPergunta(pergunta)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>

                    <div class="mt-2" *ngIf="personal">
                      <label for="resposta-{{ i }}">Resposta:</label>
                      <input
                        type="text"
                        id="{{ pergunta.question }}"
                        class="form-control"
                        name="{{ pergunta.question }}"
                        [(ngModel)]="pergunta.response"
                        #inputNomeCompleto="ngModel"
                      />
                    </div>
                  </li>
                </ul>
              </div>

              <div
                class="col-md-12 col-12"
                *ngIf="
                  tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id != 1
                "
              >
                <div class="form-group">
                  <label for="ttt"
                    >Enviar para o aluno responder de forma remota</label
                  >
                  <ui-switch
                    class="switchery switchery-warning"
                    id="ttt"
                    [checked]="isEnviarParaAluno"
                    (change)="changeEnviarParaAluno($event)"
                  ></ui-switch>
                </div>
              </div>
            </ng-template>

            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  *ngIf="
                    (tipoAvaliacaoSelecionada &&
                      tipoAvaliacaoSelecionada.id == 1) ||
                    (avaliation &&
                      (avaliation.category == 1 || avaliation.category == null))
                  "
                  type="button"
                  class="btn btn-lg btn-secondary mr-2"
                  (click)="
                    !canEdit && avaliation
                      ? activeModal.close('Close click')
                      : null
                  "
                  awPreviousStep
                >
                  Voltar
                </button>

                <button
                  *ngIf="
                    tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id != 1
                  "
                  type="button"
                  class="btn btn-lg btn-secondary mr-2"
                  (click)="
                    !canEdit && avaliation
                      ? activeModal.close('Close click')
                      : null
                  "
                  awPreviousStep
                  (click)="clearQuestions()"
                >
                  Voltar
                </button>

                <button
                  *ngIf="
                    (tipoAvaliacaoSelecionada &&
                      tipoAvaliacaoSelecionada.id == 1) ||
                    (avaliation &&
                      (avaliation.category == 1 || avaliation.category == null))
                  "
                  type="button"
                  class="btn btn-lg btn-warning"
                  [disabled]="!workForm.valid"
                  awNextStep
                >
                  {{ canEdit ? "Próximo Passo" : "Avançar" }}
                </button>

                <button
                  *ngIf="
                    !avaliation &&
                    tipoAvaliacaoSelecionada &&
                    tipoAvaliacaoSelecionada.id != 1 &&
                    !isEnviarParaAluno
                  "
                  type="button"
                  class="btn btn-lg btn-warning mr-2"
                  [disabled]="!flagAvaliacaoDobrasCompleta"
                  awNextStep
                  (click)="submitWebAvaliacao(true)"
                >
                  {{ this.idAvaliacao ? "Atualizar" : "Finalizar" }}
                </button>

                <button
                  *ngIf="
                    !avaliation &&
                    tipoAvaliacaoSelecionada &&
                    tipoAvaliacaoSelecionada.id != 1 &&
                    isEnviarParaAluno
                  "
                  type="button"
                  class="btn btn-lg btn-warning"
                  [disabled]="
                    !relation ||
                    !avaliacaoSelecionada ||
                    avaliacaoSelecionada.questions.length == 0
                  "
                  awNextStep
                  (click)="submitWebAvaliacao(false)"
                >
                  Enviar para aluno
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>

        <aw-wizard-step
          [stepTitle]="'Fotos/Arquivos'"
          [navigationSymbol]="{ symbol: '&#xf16d;', fontFamily: 'FontAwesome' }"
          *ngIf="
            (tipoAvaliacaoSelecionada && tipoAvaliacaoSelecionada.id == 1) ||
            (avaliation &&
              (avaliation.category == 1 || avaliation.category == null))
          "
        >
          <form #addressForm="ngForm" class="editForm" novalidate>
            <h4 class="head text-center mt-3">
              {{
                !(!canEdit && avaliation)
                  ? "Requisite fotos ou arquivos para o aluno (opcional)"
                  : "Fotos/Arquivos da Avaliação"
              }}
            </h4>
            <br />

            <ng-template [ngIf]="!canEdit">
              <div class="form-row">
                <ul class="list-group mb-2" style="width: 100%">
                  <li
                    *ngFor="let req of listaRequisicoes"
                    class="list-group-item"
                  >
                    <div class="media">
                      <span class="dot align-middle mr-2">{{
                        listaRequisicoes.indexOf(req) + 1
                      }}</span>
                      <div class="media-body">
                        <h5 class="mt-0">{{ req.observationFile }}</h5>
                        <button
                          *ngIf="req.urlFile"
                          class="btn btn-warning btn-sm"
                          (click)="downloadImage(req.urlFile)"
                        >
                          <i class="ft ft-download"></i>
                        </button>
                      </div>

                      <ng-template [ngIf]="isItImage(req.urlFile)">
                        <img
                          [src]="
                            req.urlFile
                              ? req.urlFile
                              : '../../assets/img/portrait/small/noimage.png'
                          "
                          class="rounded-circle"
                          [ngStyle]="{
                            cursor: req.urlFile ? 'pointer' : 'default'
                          }"
                          style="cursor: pointer"
                          (click)="
                            req.urlFile
                              ? viewMidia(req.urlFile, contentImg)
                              : null
                          "
                          height="100"
                          width="100"
                        />
                      </ng-template>

                      <ng-template [ngIf]="!isItImage(req.urlFile)">
                        <img
                          [src]="
                            '../../assets/img/portrait/small/download-circular-button.png'
                          "
                          class="rounded-circle"
                          style="cursor: pointer"
                          (click)="
                            req.urlFile ? downloadFile(req.urlFile) : null
                          "
                          height="100"
                          width="100"
                        />
                      </ng-template>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-template>

            <ng-template [ngIf]="canEdit">
              <div class="form-row mb-2">
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="basic-form-9">Requisição</label>
                    <input
                      type="text"
                      id="basic-form-9"
                      class="form-control"
                      name="fname"
                      [(ngModel)]="requisicaoParaAdicionar"
                      placeholder="Ex.: Por favor tire uma foto de seu corpo de frente"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <label for="basic-form-2">Tipo de Resposta</label>
                  <ng-select
                    [items]="tiposFiles"
                    bindLabel="name"
                    name="fname2"
                    placeholder="Selecionar tipo de resposta"
                    id="basic-types-2"
                    [(ngModel)]="tipoFileSelecionado"
                  ></ng-select>
                </div>
                <div class="col-md-3 col-12">
                  <div class="form-group">
                    <label for="t2">Obrigatoriedade</label>
                    <ui-switch
                      class="switchery switchery-warning"
                      id="t2"
                      [checked]="isRequisicaoObrigatoria"
                      (change)="changeObrigatoriedadeRequisicao($event)"
                    ></ui-switch>
                  </div>
                </div>
              </div>
              <div class="form-row mb-2">
                <div class="col-md-6 col-6" *ngIf="idRequisicaoEditada">
                  <button
                    class="btn btn-danger float-left"
                    type="button"
                    [disabled]="
                      !requisicaoParaAdicionar || !tipoFileSelecionado
                    "
                    (click)="cancelarEdicaoRequisicao()"
                  >
                    Cancelar
                  </button>
                </div>
                <div
                  [ngClass]="
                    idRequisicaoEditada ? 'col-md-6 col-6' : 'col-md-12 col-12'
                  "
                >
                  <button
                    class="btn gradient-mint float-right"
                    type="button"
                    [disabled]="
                      !requisicaoParaAdicionar || !tipoFileSelecionado
                    "
                    (click)="
                      idRequisicaoEditada
                        ? editarRequsicao()
                        : adicionarRequisicao()
                    "
                  >
                    {{ idRequisicaoEditada ? "Atualizar" : "Adicionar" }}
                  </button>
                </div>
              </div>
              <div class="form-row">
                <ul
                  class="list-group mb-2"
                  [dragula]="'bag-one'"
                  [(dragulaModel)]="listaRequisicoes"
                  style="width: 100%"
                >
                  <li
                    *ngFor="let req of listaRequisicoes"
                    class="list-group-item draggable"
                  >
                    <div class="media">
                      <span class="dot align-middle mr-2">{{
                        listaRequisicoes.indexOf(req) + 1
                      }}</span>
                      <div class="media-body">
                        <h5 class="mt-0">{{ req.observationFile }}</h5>
                        <span>{{ req.labelType }}</span>
                      </div>
                      <div class="d-none d-md-block">
                        <i
                          class="fa fa-reorder mr-2 text-muted"
                          placement="top"
                          ngbTooltip="Arraste para reordenar esta requisição."
                        ></i>
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-icon round mr-1 mb-1"
                          placement="top"
                          ngbTooltip="Duplicar requisição."
                          (click)="duplicarRequisicao(req)"
                        >
                          <i class="fa fa-copy"></i>
                        </button>
                      </div>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-icon round mr-1 mb-1"
                        placement="top"
                        [ngbTooltip]="'Editar requisição.'"
                        (click)="liberarEdicaoRequisicao(req)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        *ngIf="!(!canEdit && avaliation)"
                        type="button"
                        class="btn btn-outline-danger btn-icon round mr-1 mb-1"
                        (click)="removerRequisicao(req)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>

                    <div class="mt-2" *ngIf="personal">
                      <label *ngIf="!imagePreviews[i]" for="file-upload-{{ i }}"
                        >Carregar arquivo:</label
                      >
                      <input
                        *ngIf="!imagePreviews[i]"
                        type="file"
                        id="file-upload-{{ i }}"
                        class="form-control-file"
                        (change)="onFileSelected($event, req)"
                        [accept]="req.allowedTypes || '*'"
                      />
                      <div *ngIf="req.imagePreview" class="image-preview mt-2">
                        <img
                          [src]="req.imagePreview"
                          alt="Prévia da imagem"
                          class="img-thumbnail"
                        />
                        <button
                          class="btn btn-danger btn-sm mt-1 ml-4"
                          (click)="removeImagePreview(req)"
                        >
                          Remover
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-template>

            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg btn-secondary"
                  [ngClass]="canEdit ? 'mr-2' : ''"
                  awPreviousStep
                >
                  Voltar
                </button>
                <button
                  *ngIf="canEdit"
                  type="button"
                  class="btn btn-lg btn-warning"
                  [disabled]="
                    !workForm.valid ||
                    (listaPerguntas == null && listaRequisicoes == null)
                  "
                  awNextStep
                  (click)="submitAvaliacao()"
                >
                  {{ this.idAvaliacao ? "Atualizar" : "Finalizar" }}
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>
<ng-template let-close="close" #contentImg>
  <div class="modal-body">
    <IMG style="width: 100%; height: 100%" [src]="urlToShow" />
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="close('close buttton')"
    >
      Fechar
    </button>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
