import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-avaliacao-modal",
  templateUrl: "./avaliacao-modal.component.html",
  styleUrls: ["./avaliacao-modal.component.scss"],
})
export class AvaliacaoModalComponent {
  @Input() public avaliation: any;
  @Input() public relation: any;
  @Input() public perguntasPadrao: any[] = [];
  @Input() public arquivosPadrao: any[] = [];
  @Input() public canEdit: boolean = true;
  @Output() public onSave = new EventEmitter<any>();
  @Output() public onClose = new EventEmitter<void>();

  requisicaoParaAdicionar: string = "";
  tipoFileSelecionado: any = null;
  isRequisicaoObrigatoria: boolean = false;
  idRequisicaoEditada: any = null;

  listaRequisicoes: any[] = [];
  listaRequisicoesDeletadas: any[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  adicionarRequisicao() {
    if (!this.requisicaoParaAdicionar || !this.tipoFileSelecionado) {
      return;
    }

    const novaRequisicao = {
      id: Date.now(),
      observationFile: this.requisicaoParaAdicionar,
      typeFile: this.tipoFileSelecionado,
      isRequired: this.isRequisicaoObrigatoria,
    };

    this.listaRequisicoes.push(novaRequisicao);
    this.cancelarEdicaoRequisicao();
  }

  editarRequisicao() {
    const requisicao = this.listaRequisicoes.find(
      (r) => r.id === this.idRequisicaoEditada
    );
    if (requisicao) {
      requisicao.observationFile = this.requisicaoParaAdicionar;
      requisicao.typeFile = this.tipoFileSelecionado;
      requisicao.isRequired = this.isRequisicaoObrigatoria;
    }
    this.cancelarEdicaoRequisicao();
  }

  cancelarEdicaoRequisicao() {
    this.requisicaoParaAdicionar = "";
    this.tipoFileSelecionado = null;
    this.isRequisicaoObrigatoria = false;
    this.idRequisicaoEditada = null;
  }

  removerRequisicao(req: any) {
    this.listaRequisicoes = this.listaRequisicoes.filter((r) => r !== req);
    if (req.id) {
      this.listaRequisicoesDeletadas.push(req);
    }
  }

  liberarEdicaoRequisicao(req: any) {
    this.idRequisicaoEditada = req.id;
    this.requisicaoParaAdicionar = req.observationFile;
    this.tipoFileSelecionado = req.typeFile;
    this.isRequisicaoObrigatoria = req.isRequired;
  }

  duplicarRequisicao(req: any) {
    const novaRequisicao = { ...req, id: Date.now() };
    this.listaRequisicoes.push(novaRequisicao);
  }

  changeObrigatoriedadeRequisicao(event: boolean) {
    this.isRequisicaoObrigatoria = event;
  }

  isItImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  downloadImage(url: string) {
    const link = document.createElement("a");
    link.href = url;
    link.download = "image";
    link.click();
  }

  downloadFile(url: string) {
    const link = document.createElement("a");
    link.href = url;
    link.download = "file";
    link.click();
  }

  viewMidia(url: string) {
    window.open(url, "_blank");
  }

  salvarAvaliacao() {
    const avaliacaoSalva = {
      ...this.avaliation,
      requisicoes: this.listaRequisicoes,
      requisicoesDeletadas: this.listaRequisicoesDeletadas,
    };

    this.onSave.emit(avaliacaoSalva);
    this.activeModal.close();
  }

  fecharModal() {
    this.onClose.emit();
    this.activeModal.dismiss();
  }
}
